<template>
  <v-card class="side-cart" :elevation="0" tile>
    <div class="side-cart__container">
      <div
        class="d-flex justify-space-between align-center pa-4 side-cart__header"
      >
        <h3 class="product-ttl">Basket</h3>
        <v-btn
          @click="$emit('toggleSideCart', false)"
          :ripple="false"
          class="pa-0"
          text
        >
          <span>Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <div class="side-cart__body pa-4">
        <div
          v-for="(item, index) in cartItems"
          :key="index"
          class="d-flex text-center justify-space-between product cursor-pointer"
          @click="$emit('goToProduct', item.product.slug)"
        >
          <div>
            <img
              v-if="item.variation.id"
              :src="
                item.variation?.product_gallery?.length &&
                baseUrl + item.variation?.product_gallery[0]
              "
              width="89"
              height="83"
              alt=""
            />
            <img
              v-else
              :src="item.product && baseUrl + item.product.photo"
              width="89"
              height="83"
              alt=""
            />
          </div>

          <div>
            <h3 class="product__title">
              {{ item.product?.name[localeLang] }}
            </h3>
            <span
              v-if="item?.variation?.title?.ru"
              class="product__options d-block"
            >
              Цвет: {{ item?.variation?.title?.ru }}
            </span>
            <div
              v-if="item?.variation"
              class="product__options d-flex flex-column"
            >
              <span v-show="item.size.size" class="price">
                Размер: {{ `${item.size.size}` }}
              </span>
              <span class="count">Колличество: {{ item.size.count }}</span>
              <div>
                <span 
                  v-show="item?.size?.price?.discount?.view !== '0'" 
                  class="price old_price"
                >
                  {{ item?.size?.price?.view }}
                  {{ item.size?.currency?.currency?.currency }}
                </span>
              </div>
              <span class="price">
                <span class="value-price">
                  {{
                    productPrice(
                      item?.size?.price?.view, 
                      item?.size?.price?.discount?.view
                    )
                  }}
                    {{ item.size?.currency?.currency?.currency }}
                </span>
              </span>
            </div>
          </div>

          <v-btn
            class="px-0 d-flex align-start"
            icon
            :ripple="false"
            @click.stop="$emit('removeCartItem', item.cartId)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- Moda-total -->

      <v-divider style="border-color: #e1e1e1" class="my-0"></v-divider>
      <div class="side-cart__footer container-fluid px-3 pb-12">
        <div class="columns">
          <span>Summary:</span>

          <div>
            <span>
              {{ totalPrice }}
              {{ cartItems[0]?.size?.currency?.currency?.currency}}
            </span>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn view mx-auto mb-3"
            data-dismiss="modal"
            @click="$emit('openBasket')"
          >
            <span>VIEW BASKET</span>
          </button>
          <button type="button" class="btn ordering mx-auto"> ORDERING </button>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: State_Auth } = createNamespacedHelpers('auth')
export default {
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeLang: '',
      baseUrl: '',
    }
  },
  computed: {
    ...State_Auth(['localeLang']),
    totalPrice() {
      let total = 0
      const result = this.cartItems.map((i) => {
        if (+i.size.price.discount.view) {
          return {
            sum:
              i.size.count * (i.size.price.view - i.size.price.discount.view),
          }
        } else {
          return {
            sum: i.size.count * i.size.price.view,
          }
        }
      })
      total = result.reduce((acc, curr) => {
        return (acc += curr.sum)
      }, 0)
      return total.toFixed(2)
    },
    totalCount() {
      let total = 0
      this.cartItems.forEach((item) => {
        total += item.colors.sizes.reduce((sum, curr) => sum + curr.count, 0)
      })
      return total
    },
  },
  methods: {
    productPrice(price, discount) {
      return Number(price - discount).toFixed(2)
    },
  },
  mounted() {
    this.activeLang = this.$store.state.language
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style lang="scss" scoped>
.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 344px;
  height: 100%;
  z-index: 20;
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__body {
    flex-grow: 1;
    overflow: auto;
  }
  &__footer {
    .columns {
      span {
        font-size: 24px;
        color: #666666;
        font-family: 'Roboto Condensed';
      }
      & > span {
        font-weight: 500;
        font-family: 'Alegreya Sans';
      }
    }
  }
  .product {
    &__title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    &__options {
      font-size: 14px;
      color: #b2b2b2;
    }
    &__title,
    &__options {
      text-align: left;
    }
  }
  .view {
    background-color: #cfebcc;
  }
  .ordering {
    background-color: #eeeeee;
  }
}
.product {
  &__title {
    font-size: 24px;
    font-weight: 500;
  }
  .count,
  .delimetr {
    font-size: 14px;
    color: #9a9a9a;
  }
  .price {
    font-size: 14px;
    color: #666666;
  }
  .old_price {
    font-size: 14px;
    text-decoration: line-through;
    color: #000;
  }
}
.v-btn--icon:before {
  content: none;
}
</style>
